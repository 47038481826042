jQuery(function() {
	jQuery('#filter-highlights-reset').on('click', function() {
		jQuery('#filter-search').val('');
		jQuery('#filter-institute').val('');
		jQuery('#filter-perpage').val('10');
		jQuery('#filter-highlights').trigger('submit');
	});

	jQuery(".highlight-body a").each(function(e) {
		jQuery(this).attr("target","_blank");
		jQuery(this).attr("rel", "noopener");
	});
});