jQuery(function(){
    jQuery('#filter-events-reset').on('click', function() {
		jQuery('#filter-search').val('');
		jQuery('#filter-startdate').val('');
		jQuery('#filter-enddate').val('');
		jQuery('#filter-institute').val('');
		jQuery('#filter-perpage').val('10');

		jQuery('#filter-date-warning').addClass('d-none').removeClass('d-block');
		jQuery('#fb-startdate-label, #fb-enddate-label').removeClass('fb-warning-text');
		jQuery('#fb-submit').prop('disabled', false);

		jQuery('#filter-events').trigger('submit');
	});
});