const { start } = require("@popperjs/core");

jQuery(function() {

	// nav menu button states
	jQuery('#nav-menu').on('show.bs.collapse', function () {
		jQuery('#nav-toggle').addClass('is-active');
	});
	jQuery('#nav-menu').on('hide.bs.collapse', function () {
		jQuery('#nav-toggle').removeClass('is-active');
	});


	// search box
	jQuery('#search-target-events').on('change', function(e) {
		jQuery('#footer-search').attr('action', '/events/');
	});

	jQuery('#search-target-highlights').on('change', function(e) {
		jQuery('#footer-search').attr('action', '/highlights/');
	});

	jQuery('#search-target-videos').on('change', function(e) {
		jQuery('#footer-search').attr('action', '/videos/');
	});


	//flatpickr
	jQuery('#filter-startdate, #filter-enddate').flatpickr({
		altInput: true,
		altFormat: "F j, Y",
		dateFormat: "Y-m-d",
		onChange: function(selectedDates, dateStr, instance) {
			start_date = jQuery('#filter-startdate').val();
			end_date = jQuery('#filter-enddate').val();

			if ((!!start_date) && (!!end_date)) {
				if (start_date > end_date) {
					console.log('start date after end date');
					jQuery('#filter-date-warning').addClass('d-block').removeClass('d-none');
					jQuery('#fb-startdate-label, #fb-enddate-label').addClass('fb-warning-text');
					jQuery('#fb-submit').prop('disabled', true);
				}
				else {
					jQuery('#filter-date-warning').addClass('d-none').removeClass('d-block');
					jQuery('#fb-startdate-label, #fb-enddate-label').removeClass('fb-warning-text');
					jQuery('#fb-submit').prop('disabled', false);
				}
			}
		}
	});
});