require('./bootstrap');

require('flatpickr');

require('./frontend-ui');

require('./frontend-highlights');

require('./frontend-events');

require('./frontend-videos');